import { Button, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

const apiUrl = "https://10.10.12.214:3000";

const bufferToBase64 = (buffer) =>
  btoa(String.fromCharCode(...new Uint8Array(buffer)));
const base64ToBuffer = (base64) =>
  Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));

function getUserHandle() {
  return localStorage.getItem('userHandle') || '';
}

function setUserHandle(userHandle) {
  localStorage.setItem('userHandle', userHandle);
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // 获取 用户信息
    fetch(`${apiUrl}/user-info`, {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify({
        userHandle: getUserHandle(),
      }),
      credentials: "include",
    }).then((res) => {
      res.json().then((data) => {
        setIsLoading(false);
        setUserData(data);
      }).catch(() => {
        setIsLoading(false);
      })
    }).catch(() => {
      setIsLoading(false);
    })
  }, [])

  const register = async () => {
    try {
      // 获取challenge
      const credentialCreationOptions = await (
        await fetch(`${apiUrl}/registration-options`, {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        })
      ).json();

      const rawChallenge = credentialCreationOptions.challenge;

      credentialCreationOptions.challenge = new Uint8Array(
        credentialCreationOptions.challenge.data
      );
      credentialCreationOptions.user.id = new Uint8Array(
        credentialCreationOptions.user.id.data
      );
      credentialCreationOptions.user.name = Date.now().toString();
      credentialCreationOptions.user.displayName = "";

      const credential = await navigator.credentials.create({
        publicKey: credentialCreationOptions,
      });

      const credentialId = bufferToBase64(credential.rawId);
      console.log('authenticator response', Credential);
      // localStorage.setItem("credential", JSON.stringify({ credentialId }));

      const data = {
        rawId: credentialId,
        challenge: rawChallenge,
        response: {
          attestationObject: bufferToBase64(
            credential.response.attestationObject
          ),
          clientDataJSON: bufferToBase64(credential.response.clientDataJSON),
          id: credential.id,
          type: credential.type,
        },
      };

      const res = await fetch(`${apiUrl}/register`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ credential: data }),
          credentials: "include",
      });
      if (res.status === 200) {
        alert("注册成功");
        const data = await res.json();
        setUserHandle(data.id);
        window.location.reload();
      } else {
        alert("注册失败");
      }
    } catch (e) {
      console.error("registration failed", e);
      alert(e.message);
    }
  };

  const authenticate = async () => {
    try {
      const credentialRequestOptions = await (
        await fetch(`${apiUrl}/authentication-options`, {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        })
      ).json();
      // const { credentialId } = JSON.parse(localStorage.getItem("credential"));
      const rawChallenge = credentialRequestOptions.challenge;
      credentialRequestOptions.challenge = new Uint8Array(
        credentialRequestOptions.challenge.data
      );
      credentialRequestOptions.allowCredentials = [
        // {
        //   // id: base64ToBuffer(credentialId),
        //   // type: "public-key",
        //   // transports: ["internal"],
        // },
      ];

      console.log("credentialRequestOptions", credentialRequestOptions);
      const credential = await navigator.credentials.get({
        publicKey: credentialRequestOptions,
      });

      const data = {
        rawId: bufferToBase64(credential.rawId),
        challenge: rawChallenge,
        response: {
          authenticatorData: bufferToBase64(
            credential.response.authenticatorData
          ),
          signature: bufferToBase64(credential.response.signature),
          userHandle: bufferToBase64(credential.response.userHandle),
          clientDataJSON: bufferToBase64(credential.response.clientDataJSON),
          id: credential.id,
          type: credential.type,
        },
      };

      const response = await fetch(`${apiUrl}/authenticate`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ credential: data }),
        credentials: "include",
      });

      if (response.status !== 200) {
        alert('登录失败');
      } else {
        const data = await response.json();
        if (data.id) {
          setUserHandle(data.id);
          alert("登录成功");
          window.location.reload();
        } else {
          alert("登录失败");
        }
      }
    } catch (e) {
      alert("authentication failed");
    }
  };

  const submit = async () => {
    const res = await fetch(`${apiUrl}/user-info`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ secret: userData?.secret || '', userHandle: getUserHandle() }),
      credentials: "include",
    });
    if (res.status === 200) {
      alert("提交成功");
    } else {
      alert("提交失败");
    }
  };

  const logout = async () => {
    setUserHandle("");
    setUserData(null);
    alert("退出成功");
  };

  if (isLoading) {
    return <div>loading...</div>
  }

  return (
    <div className="App">
      <Stack direction="row" spacing={2}>
        {userData ? (
          <Stack spacing={4}>
            <Button color="error" onClick={logout}>退出登录</Button>
            <Stack direction="row" spacing={2}>
              <TextField
                label="我的秘密"
                variant="outlined"
                value={userData?.secret}
                onChange={(e) => {
                  setUserData({ ...(userData || {}), secret: e.target.value });
                }}
              />
              <Button variant="contained" color="primary" onClick={submit}>
                提交
              </Button>
            </Stack>
          </Stack>
        ) : (
          <>
            <Button variant="contained" color="primary" onClick={register}>
              注册
            </Button>
            <Button variant="contained" color="primary" onClick={authenticate}>
              登录
            </Button>
          </>
        )}
      </Stack>
    </div>
  );
}

export default App;
